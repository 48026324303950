@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	--color-primary-200: #c7e3ff;
	--color-primary-300: #a1d0ff;
	--color-primary-400: #70b8ff;
	--color-primary-500: #3b9eff;
}

html {
	scroll-behavior: smooth;
}

@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@400;500;700&display=swap");

.font-plus-jakarta {
	font-family: "Plus Jakarta Sans", sans-serif;
	font-optical-sizing: auto;
	letter-spacing: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "Plus Jakarta Sans", serif;
	font-weight: 700;
	font-optical-sizing: auto;
	font-style: normal;
}
.text-display-large {
	@apply text-[52px] font-semibold;
}
.text-display-medium {
	@apply text-[40px] font-semibold;
}
.text-display-small {
	@apply text-[36px] font-semibold;
}
.text-title-xl {
	@apply text-[28px] font-bold;
}
.text-title-lg {
	@apply text-[22px] font-bold;
}
.text-title-md {
	@apply text-[16px] font-semibold;
}
.text-title-sm {
	@apply text-[14px] font-semibold;
}
.text-label-xl {
	@apply text-[15px] font-medium;
}
.text-label-lg {
	@apply text-[14px] font-medium;
}
.text-label-md {
	@apply text-[12px] font-medium;
}
.text-label-sm {
	@apply text-[11px] font-medium;
}

h1 {
	@apply text-display-large;
}
h2 {
	@apply text-display-medium;
}
h3 {
	@apply text-display-small;
}
h4 {
	@apply text-title-xl;
}
h5 {
	@apply text-title-lg;
}
h6 {
	@apply text-title-md;
}
button {
	@apply btn flex items-center;
}
.btn {
	@apply px-3 py-2 rounded leading-none;
}
.container {
	@apply mx-auto max-w-7xl p-0;
}
.resume-content ul:not(.resume-user-quick-info) {
	list-style: disc;
}
.resume-content ul:not(.pl-3 > ul) {
	padding-left: 24px;
}
@keyframes moveDot {
	0% {
		left: -10px;
		top: -10px;
	}
	25% {
		left: calc(100% + 10px);
		top: -10px;
	}
	50% {
		left: calc(100% + 10px);
		top: calc(100% + 10px);
	}
	75% {
		left: -10px;
		top: calc(100% + 10px);
	}
	to {
		left: -10px;
		top: -10px;
	}
}
.shiny-dot {
	position: absolute;
	width: 10px;
	height: 10px;
	background: #fff;
	border-radius: 50%;
	filter: blur(4px);
	opacity: 0.7;
	animation: moveDot 8s linear infinite;
}

.font-inter * :not(i, a, button, .fa) {
	font-family: "Inter", sans-serif !important;
}

.content-wrapper ul {
	@apply list-disc pl-4 font-inter;
}

/* scroll bar */
/* width */
::-webkit-scrollbar {
	width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 0.5px #f7f8f8;
	border-radius: 50%;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #d7dae0;
	border-radius: 10px;
}

/* For Employers Page Styles */

/* Common Layout */
.employers-container {
	max-width: 72rem; /* 1152px - matches max-w-7xl */
	margin: 0 auto;
	padding: 0 1rem;
}

@media (min-width: 640px) {
	.employers-container {
		padding: 0 1.5rem;
	}
}

@media (min-width: 1024px) {
	.employers-container {
		padding: 0 2rem;
	}
}

/* Hero Section */
.hiring-hero {
	padding: 0.5rem;
	background-color: #1e40af; /* primary-800 */
	border-radius: 1.5rem;
	overflow: visible;
	position: relative;
	margin-top: 0;
}

@media (min-width: 640px) {
	.hiring-hero {
		padding: 1rem;
		margin-top: 0;
	}
}

@media (min-width: 1024px) {
	.hiring-hero {
		padding: 1.5rem;
		margin-top: 0;
	}
}

.hiring-hero__inner {
	padding: 2rem 1rem 0.5rem;
}

@media (min-width: 1024px) {
	.hiring-hero__inner {
		padding: 3rem 1rem 1rem;
	}
}

.hiring-hero__content {
	max-width: 48rem;
	margin: 0 auto;
	text-align: center;
	display: flex;
	flex-direction: column;
	gap: 2rem;
}

.hiring-hero__title {
	font-size: 2.25rem; /* text-4xl */
	font-weight: 700;
	color: white;
	line-height: 1.1;
	letter-spacing: -0.025em;
	font-family: "Plus Jakarta Sans", sans-serif;
}

@media (min-width: 640px) {
	.hiring-hero__title {
		font-size: 3rem; /* text-5xl */
	}
}

@media (min-width: 1280px) {
	.hiring-hero__title {
		font-size: 3.75rem; /* text-6xl */
		line-height: 1;
	}
}

.hiring-hero__description {
	font-size: 1.25rem;
	color: rgba(255, 255, 255, 0.8);
	line-height: 1.75;
	font-family: "Inter", sans-serif;
}

/* Keep the Button component styling from ui/button */

/* Trusted Companies */
.trusted-companies {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 1rem;
	padding-top: 1rem;
}

.trusted-companies__text {
	font-size: 0.875rem;
	font-weight: 500;
	color: rgba(255, 255, 255, 0.8);
	font-family: "Inter", sans-serif;
}

.trusted-companies__logos {
	display: flex;
	gap: 0.5rem;
}

.company-logo {
	width: 2rem;
	height: 2rem;
	background-color: rgba(29, 78, 216, 0.5); /* primary-700/50 */
	border-radius: 9999px;
	border: 1px solid #1d4ed8; /* primary-700 */
}

/* Video Section */
.hiring-hero__video {
	max-width: 64rem;
	margin: 0 auto;
	width: 100%;
	transform: translateY(6rem); /* translate-y-24 */
}

/* Platform Features Section */
.platform-features {
	padding-top: 16rem;
	padding-bottom: 9rem;
}

.platform-features__container {
	max-width: 1536px; /* matches Tailwind's container at 2xl breakpoint */
	margin: 0 auto;
	padding: 0 1rem;
}

@media (min-width: 640px) {
	.platform-features__container {
		max-width: 640px;
		padding: 0 1.5rem;
	}
}

@media (min-width: 768px) {
	.platform-features__container {
		max-width: 768px;
	}
}

@media (min-width: 1024px) {
	.platform-features__container {
		max-width: 1024px;
		padding: 0 2rem;
	}
}

@media (min-width: 1280px) {
	.platform-features__container {
		max-width: 1280px;
	}
}

@media (min-width: 1536px) {
	.platform-features__container {
		max-width: 1536px;
	}
}

.platform-features__grid {
	display: flex;
	flex-direction: column;
	gap: 3rem;
}

@media (min-width: 1024px) {
	.platform-features__grid {
		flex-direction: row;
		gap: 3rem;
	}
}

.platform-features__content {
	width: 100%;
	display: flex;
	flex-direction: column;
}

@media (min-width: 1024px) {
	.platform-features__content {
		width: 30%;
	}
}

.platform-features__title {
	font-size: 3rem;
	font-weight: 700;
	color: rgb(17, 24, 39);
	letter-spacing: -0.025em;
	font-family: "Plus Jakarta Sans", sans-serif;
	margin-bottom: 1rem; /* mb-4 - matches comparison section */
	line-height: 1.2; /* matches comparison section */
}

.platform-features__description {
	font-size: 1.25rem;
	color: rgb(55, 65, 81);
	font-family: "Inter", sans-serif;
	line-height: 1.5;
	margin-bottom: 4rem;
	max-width: 48rem;
	margin-left: auto;
	margin-right: auto;
}

.platform-features__cards {
	width: 100%;
	display: grid;
	gap: 1rem;
	grid-template-columns: 1fr;
}

@media (min-width: 768px) {
	.platform-features__cards {
		grid-template-columns: repeat(3, 1fr);
	}
}

@media (min-width: 1024px) {
	.platform-features__cards {
		width: 70%;
	}
}

.feature-card {
	position: relative;
	background: white;
	border-radius: 1rem;
	padding: 2rem;
	border: 1px solid rgb(229, 231, 235);
	display: flex;
	flex-direction: column;
	min-height: 400px;
	transition: all 0.3s;
}

.feature-card:hover {
	background: rgb(249, 250, 251);
}

.feature-card__icon-wrapper {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	margin-bottom: 2rem;
}

.feature-card__icon {
	height: 3rem;
	width: 3rem;
	background: #ede9e3;
	border-radius: 0.75rem;
	display: flex;
	align-items: center;
	justify-content: center;
}

.feature-card__icon svg {
	width: 1.5rem;
	height: 1.5rem;
}

.feature-card__content {
	margin-top: auto;
}

.feature-card__title {
	color: rgb(17, 24, 39);
	font-size: 1.5rem;
	font-weight: 500;
	margin-bottom: 0.75rem;
	font-family: "Inter", sans-serif;
	line-height: 1.2;
}

.feature-card__description {
	color: rgb(55, 65, 81);
	font-size: 0.875rem;
	line-height: 1.625;
	margin-bottom: 2rem;
}

.feature-card__learn-more {
	position: relative;
	height: 3rem;
}

.feature-card__learn-more-wrapper {
	position: absolute;
	left: 0;
	display: inline-flex;
	height: 3rem;
	align-items: center;
	overflow: hidden;
}

.feature-card__learn-more-button {
	position: relative;
	z-index: 10;
	width: 3rem;
	height: 3rem;
	border-radius: 9999px;
	background-color: rgb(37, 99, 235);
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.3s;
}

.feature-card:hover .feature-card__learn-more-button {
	width: 10rem;
}

.feature-card__learn-more-icon {
	position: absolute;
	left: 0.875rem;
	color: white;
	width: 1.25rem;
	height: 1.25rem;
	transition: all 0.3s;
}

.feature-card__learn-more-text {
	position: absolute;
	left: 3rem;
	font-size: 0.9375rem;
	font-weight: 500;
	color: white;
	white-space: nowrap;
	opacity: 0;
	transition: opacity 0.2s;
	transition-delay: 0.1s;
	padding-right: 1.25rem;
}

.feature-card:hover .feature-card__learn-more-text {
	opacity: 1;
}

/* Comparison Section */
.comparison-section {
	margin-top: -6rem; /* -mt-24 */
	padding: 6rem 0; /* py-24 */
}

.comparison-section__container {
	max-width: 72rem; /* container */
	margin: 0 auto;
	padding: 0 1rem; /* px-4 */
}

@media (min-width: 768px) {
	.comparison-section__container {
		padding: 0 1.5rem; /* md:px-6 */
	}
}

@media (min-width: 1024px) {
	.comparison-section__container {
		padding: 0 2rem; /* lg:px-8 */
	}
}

.comparison-section__grid {
	display: grid;
	gap: 1.5rem; /* gap-6 */
	grid-template-columns: 1fr;
}

@media (min-width: 768px) {
	.comparison-section__grid {
		grid-template-columns: repeat(2, 1fr); /* md:grid-cols-2 */
	}
}

.comparison-card {
	position: relative;
	padding: 2rem; /* p-8 */
	border-radius: 1rem; /* rounded-2xl */
	background: white;
	border: 1px solid rgb(229, 231, 235); /* border border-gray-200 */
	overflow: hidden;
}

.comparison-card__gradient {
	position: absolute;
	inset: -1rem;
	background: linear-gradient(to bottom, white, transparent);
	mask-image: radial-gradient(circle at center bottom, black 40%, transparent 80%);
	-webkit-mask-image: radial-gradient(circle at center bottom, black 40%, transparent 80%);
	background-image: url("https://hebbkx1anhila5yf.public.blob.vercel-storage.com/Screenshot%202025-01-17%20at%2011.27.11%E2%80%AFAM-PkWeDpEzg0NiLr2urcdqb4Ed4dYDhO.png");
	background-position: bottom;
	background-size: 120% 120%;
	background-repeat: no-repeat;
	transform: scale(1.4);
	filter: contrast(1.1) brightness(1.05);
}

.comparison-card__shine {
	position: absolute;
	inset: 0;
	mix-blend-mode: overlay;
	opacity: 0.5;
	background: linear-gradient(to bottom right, transparent, rgba(255, 255, 255, 0.2) 50%, transparent);
}

.comparison-card__content {
	position: relative;
	z-index: 10;
}

.comparison-card__title {
	font-size: 1.5rem;
	font-weight: 500;
	color: rgb(31, 41, 55); /* text-gray-800/900 */
	font-family: "Inter", sans-serif;
	margin-bottom: 0.25rem;
	line-height: 1.2;
}

.comparison-card__subtitle {
	color: rgb(17, 24, 39); /* text-gray-900 */
	margin-bottom: 2.5rem; /* mb-10 */
}

.comparison-card__list {
	display: flex;
	flex-direction: column;
}

.comparison-card__list-item {
	padding: 0.75rem 0; /* py-3 */
}

.comparison-card__list-item:first-child {
	padding-top: 0; /* first:pt-0 */
}

.comparison-card__list-item:last-child {
	padding-bottom: 0; /* last:pb-0 */
}

.comparison-card__list-item-content {
	display: flex;
	gap: 1rem; /* gap-4 */
	align-items: flex-start; /* items-start */
}

.comparison-card__list-item-icon {
	color: rgb(55, 65, 81); /* text-gray-700 */
}

.comparison-card__list-item-text {
	flex: 1;
}

.comparison-card__list-item-title {
	font-weight: 500;
	color: rgb(31, 41, 55);
	margin-bottom: 0.25rem;
	font-family: "Inter", sans-serif;
}

.comparison-card__list-item-description {
	color: rgb(55, 65, 81); /* text-gray-700 */
	font-size: 0.875rem; /* text-sm */
}

/* Update the second card's title color */
.comparison-card:nth-child(2) .comparison-card__title,
.comparison-card:nth-child(2) .comparison-card__subtitle,
.comparison-card:nth-child(2) .comparison-card__list-item-title,
.comparison-card:nth-child(2) .comparison-card__list-item-description {
	color: rgb(17, 24, 39); /* text-gray-900 */
}

.comparison-card:nth-child(2) .DprozLogoIcon {
	background: linear-gradient(45deg, #1e40af, #60a5fa, #3b82f6);
	-webkit-background-clip: text;
	background-clip: text;
	color: transparent !important;
	fill: url(#gradient);
}

.comparison-card:nth-child(2) .DprozLogoIcon path {
	fill: url(#gradient);
}

.comparison-section__header {
	text-align: center;
	margin-bottom: 4rem; /* mb-16 */
}

.comparison-section__heading {
	font-size: 3rem; /* text-5xl */
	font-weight: 700; /* font-bold */
	color: rgb(17, 24, 39); /* text-gray-900 */
	letter-spacing: -0.025em; /* tracking-tight */
	font-family: "Plus Jakarta Sans", sans-serif;
	margin-bottom: 1rem; /* mb-4 */
	max-width: 48rem; /* max-w-3xl */
	margin-left: auto;
	margin-right: auto;
	line-height: 1.2; /* Tailwind's default line height for text-5xl */
}

.comparison-section__subheading {
	font-size: 1.25rem; /* text-xl */
	color: rgb(55, 65, 81); /* text-gray-700 */
	font-family: "Inter", sans-serif;
	max-width: 48rem; /* max-w-3xl */
	margin-left: auto;
	margin-right: auto;
	line-height: 1.5;
}

/* Value Proposition Section */
.value-proposition {
	padding: 6rem 0; /* py-24 */
	overflow: hidden;
}

.value-proposition__container {
	max-width: 1536px; /* matches Tailwind's container at 2xl breakpoint */
	margin: 0 auto;
	padding: 0 1rem;
}

@media (min-width: 640px) {
	.value-proposition__container {
		max-width: 640px;
		padding: 0 1.5rem;
	}
}

@media (min-width: 768px) {
	.value-proposition__container {
		max-width: 768px;
	}
}

@media (min-width: 1024px) {
	.value-proposition__container {
		max-width: 1024px;
		padding: 0 2rem;
	}
}

@media (min-width: 1280px) {
	.value-proposition__container {
		max-width: 1280px;
	}
}

@media (min-width: 1536px) {
	.value-proposition__container {
		max-width: 1536px;
	}
}

.value-proposition__grid {
	display: grid;
	gap: 3rem; /* gap-12 */
	grid-template-columns: 1fr;
	align-items: center;
}

@media (min-width: 1024px) {
	.value-proposition__grid {
		grid-template-columns: repeat(2, 1fr); /* lg:grid-cols-2 */
	}
}

.value-proposition__image-wrapper {
	position: relative;
	aspect-ratio: 4/3;
	border-radius: 1rem; /* rounded-2xl */
	overflow: hidden;
}

.value-proposition__image-gradient {
	position: absolute;
	inset: 0;
	background: linear-gradient(
		to top right,
		rgba(219, 234, 254, 0.4),
		rgba(239, 246, 255, 0.4)
	); /* from-primary-100/40 to-primary-50/40 */
	z-index: -10;
	filter: blur(24px); /* blur-3xl */
}

.value-proposition__image {
	object-fit: cover;
	border-radius: 1rem; /* rounded-2xl */
}

.value-proposition__content {
	display: flex;
	flex-direction: column;
	gap: 3rem; /* space-y-12 */
}

.value-proposition__title {
	font-size: 3rem; /* text-5xl */
	font-weight: 700; /* font-bold */
	color: rgb(17, 24, 39); /* text-gray-900 */
	letter-spacing: -0.025em; /* tracking-tight */
	font-family: "Plus Jakarta Sans", sans-serif;
	margin-bottom: 1rem; /* mb-4 */
	line-height: 1.2;
}

.value-proposition__features {
	display: flex;
	flex-direction: column;
	gap: 2rem; /* space-y-8 */
}

.value-proposition__feature {
	display: flex;
	gap: 1.5rem; /* gap-6 */
}

.value-proposition__feature-icon {
	flex-shrink: 0;
	width: 3rem; /* w-12 */
	height: 3rem; /* h-12 */
	border-radius: 0.75rem; /* rounded-xl */
	background-color: #ede9e3;
	display: flex;
	align-items: center;
	justify-content: center;
}

.value-proposition__feature-icon svg {
	width: 1.5rem;
	height: 1.5rem;
}

.value-proposition__feature-content {
	display: flex;
	flex-direction: column;
}

.value-proposition__feature-title {
	font-size: 1.5rem;
	font-weight: 500; /* font-medium */
	color: rgb(17, 24, 39); /* text-gray-900 */
	margin-bottom: 0.5rem; /* mb-2 */
	font-family: "Inter", sans-serif;
	line-height: 1.2;
}

.value-proposition__feature-description {
	color: rgb(75, 85, 99); /* text-gray-600 */
	line-height: 1.625; /* leading-relaxed */
	max-width: 28rem; /* max-w-md */
}

/* Features Showcase Section */
.features-showcase {
	padding-top: 6rem;
	padding-bottom: 6rem;
}

.features-showcase__container {
	max-width: 1536px;
	margin: 0 auto;
	padding: 0 1rem;
}

@media (min-width: 640px) {
	.features-showcase__container {
		max-width: 640px;
		padding: 0 1.5rem;
	}
}

@media (min-width: 768px) {
	.features-showcase__container {
		max-width: 768px;
	}
}

@media (min-width: 1024px) {
	.features-showcase__container {
		max-width: 1024px;
		padding: 0 2rem;
	}
}

@media (min-width: 1280px) {
	.features-showcase__container {
		max-width: 1280px;
	}
}

@media (min-width: 1536px) {
	.features-showcase__container {
		max-width: 1536px;
	}
}

.features-showcase__grid {
	display: flex;
	flex-direction: column;
	gap: 3rem;
}

.features-showcase__content {
	width: 100%;
	text-align: center;
	margin-bottom: 4rem;
}

.features-showcase__title {
	font-size: 3rem;
	font-weight: 700;
	color: rgb(17, 24, 39);
	letter-spacing: -0.025em;
	font-family: "Plus Jakarta Sans", sans-serif;
	margin-bottom: 1rem;
	line-height: 1.2;
	max-width: 48rem;
	margin-left: auto;
	margin-right: auto;
}

.features-showcase__description {
	font-size: 1.25rem;
	color: rgb(55, 65, 81);
	font-family: "Inter", sans-serif;
	line-height: 1.5;
	max-width: 48rem;
	margin-left: auto;
	margin-right: auto;
}

.features-showcase__cards {
	width: 100%;
	display: grid;
	gap: 2rem;
	grid-template-columns: 1fr;
}

@media (min-width: 768px) {
	.features-showcase__cards {
		grid-template-columns: repeat(3, 1fr);
	}
}

.showcase-card {
	position: relative;
	background: white;
	border-radius: 1rem;
	border: 1px solid rgb(229, 231, 235);
	display: flex;
	flex-direction: column;
	min-height: 600px;
	transition: all 0.3s;
	overflow: hidden;
}

.showcase-card:hover {
	background: rgb(249, 250, 251);
}

.showcase-card__image {
	width: 100%;
	aspect-ratio: 826/552;
	position: relative;
}

.showcase-card__content {
	padding: 2rem;
	display: flex;
	flex-direction: column;
	flex: 1;
}

.showcase-card__header {
	margin-bottom: 1rem;
}

.showcase-card__title {
	color: rgb(17, 24, 39);
	font-size: 1.5rem;
	font-weight: 500;
	margin-bottom: 0.75rem;
	font-family: "Inter", sans-serif;
	line-height: 1.2;
}

.showcase-card__client {
	color: rgb(107, 114, 128);
	font-size: 0.875rem;
}

.showcase-card__description {
	color: rgb(55, 65, 81);
	font-size: 0.875rem;
	line-height: 1.625;
	margin-bottom: 2rem;
}

.showcase-card__learn-more {
	position: relative;
	height: 3rem;
	margin-top: auto;
}

.showcase-card__learn-more-wrapper {
	position: absolute;
	left: 0;
	display: inline-flex;
	height: 3rem;
	align-items: center;
	overflow: hidden;
}

.showcase-card__learn-more-button {
	position: relative;
	z-index: 10;
	width: 3rem;
	height: 3rem;
	border-radius: 9999px;
	background-color: rgb(37, 99, 235);
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.3s;
}

.showcase-card:hover .showcase-card__learn-more-button {
	width: 10rem;
}

.showcase-card__learn-more-icon {
	position: absolute;
	left: 0.875rem;
	color: white;
	width: 1.25rem;
	height: 1.25rem;
	transition: all 0.3s;
}

.showcase-card__learn-more-text {
	position: absolute;
	left: 3rem;
	font-size: 0.9375rem;
	font-weight: 500;
	color: white;
	white-space: nowrap;
	opacity: 0;
	transition: opacity 0.2s;
	transition-delay: 0.1s;
	padding-right: 1.25rem;
}

.showcase-card:hover .showcase-card__learn-more-text {
	opacity: 1;
}

/* Footer Section - For Employers Page */
.employers-footer {
	margin: 0.5rem;
	background-color: #111827; /* bg-gray-900 */
	border-radius: 1.5rem;
	overflow: hidden;
	position: relative;
}

@media (min-width: 640px) {
	.employers-footer {
		margin: 1rem;
	}
}

@media (min-width: 1024px) {
	.employers-footer {
		margin: 1.5rem;
	}
}

.employers-footer__inner {
	padding: 5rem 1rem;
}

@media (min-width: 1024px) {
	.employers-footer__inner {
		padding: 5rem 2rem;
	}
}

/* CTA Section */
.cta-section {
	padding-bottom: 6rem;
	background: white;
}

.cta-section__container {
	max-width: 72rem;
	margin: 0 auto;
	padding: 0 1rem;
}

.cta-section__content {
	max-width: 48rem;
	margin: 0 auto;
	text-align: center;
}

.cta-section__title {
	font-size: 3rem;
	font-weight: 700;
	color: rgb(17, 24, 39);
	letter-spacing: -0.025em;
	font-family: "Plus Jakarta Sans", sans-serif;
	margin-bottom: 1.5rem;
	line-height: 1.2;
}

.cta-section__description {
	font-size: 1.25rem;
	color: rgb(55, 65, 81);
	font-family: "Inter", sans-serif;
	line-height: 1.5;
	margin-bottom: 2rem;
}

.cta-section__buttons {
	display: flex;
	justify-content: center;
}

@media (max-width: 640px) {
	.cta-section__title {
		font-size: 2.25rem;
	}
}
